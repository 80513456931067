@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;0,500;1,300;1,500&display=swap');

html,
body,
#root {
  margin: 0;
  padding: 0;
  max-width: 100%;
  overflow-x: hidden;
    font-family: 'Roboto',sans-serif;
  
}

html{
  overflow-y: hidden;
}

#ll{
  width: 100vw;
  height: 100vh;
  position:fixed;
  z-index: 0;
  background-color: black;
}

#blank{
  width: 100vw;
  height:100vh;
  z-index: 0;
  pointer-events: none;
}


#whiteout{
  width: 100vw;
  background-color: white;
  opacity: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 1;
  pointer-events: none;
}

#introtext{
  display: block;
  position: fixed;
  top: 20vh;
  left:10vw;
  width: 20vw;
}

#MainText{
  font-size: 40px;
  font-weight: 300;
  color: rgba(5, 32, 105, 0.555);
  clip-path: polygon(0 0,100% 0,100% 100%,0% 100%);
  opacity: 0;
}

#info1,#info2,#info3,#info4{
  position: fixed;
  left: 10vw;
  width: 25vw;
  top:25vh;
  font-weight: 400;
  font-size: 30px;
  color: rgba(5, 32, 105, 0.6);
  opacity: 0;
}

#info1 a,#info2 a,#info3 a,#info4 a{
  font-size: 14px;
  text-decoration: underline  rgba(0, 0, 0, 0.484);
  color: #02060eae;
  margin-top: 0px;
}

#info1 a:hover,#info2 a:hover,#info3 a:hover,#info4 a:hover{
  color: rgb(255, 255, 255);
  text-decoration: underline  rgba(255, 255, 255, 0.484);
}

svg {
  position: absolute;
}

circle:nth-of-type(1) { fill: #ffffff; }
circle:nth-of-type(2) { fill: #054084; }
circle:nth-of-type(3) { fill: #9eede4; }
circle:nth-of-type(4) { fill: #21a2f2; }

#clackers {
/*   border: 1px solid white; */
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#loader{
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #06163a;
  opacity: 1;
}



.char{
  transform: translateY(30vh);
  transition: transform;
}

@media only screen and (max-width: 600px) {
  #MainText{
    font-size: 20px;
  }
}